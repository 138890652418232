<template>
  <div class="order">
    <div class="flex-column flex-fill p-3" id="articleDetail">
      <div class="row mb-3">
        <div class="col">
          <h3>Bestelldetails</h3>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary ml-1" @click="downloadPacklist"><i class="far fa-print"></i> Packliste drucken</button>
          <button class="btn btn-secondary ml-1" @click="supplier" :disabled="fields.supplierDateBack">als ausgeliefert markieren</button>
          <button @click="$router.push({ name: 'dashboard'})" class="btn btn-secondary ml-1">zur Übersicht</button>
        </div>
      </div>

      <div class="row" v-if="fields.supplierDateBack">
        <div class="col">
          <div class=" alert alert-info">
            Vorgang am {{fields.supplierDateBack}} as geliefert markiert!
          </div>
        </div>
      </div>

      <div class="row" >
        <div class="col d-flex align-items-stretch">
          <b-card class=" flex-fill mb-3" header="Bestellung">
            <div class="row">
              <strong class="col-5">Auftragsdatum:</strong>
              <span class="col-7 p-0">{{fields.orderdate}}</span>
            </div>
            <div class="row">
              <strong class="col-5">Auftrag:</strong>
              <span class="col-7 p-0" v-if="fields.order">A-{{fields.order.refnr}}</span>
            </div>
            <div class="row">
              <strong class="col-5">Auftragsbestätigung:</strong>
              <span class="col-7 p-0" v-if="fields.refnr">AB-{{fields.refnr}}</span>
            </div>
            <div class="row">
              <strong class="col-5">Kunde:</strong>
              <span class="col-7 p-0">{{fields.company.company}}</span>
            </div>
            <hr>
            <div class="row">
              <strong class="col-5">Verpackung:</strong>
              <span class="col-7 p-0" v-if="fields.packaging == 0">Standard-Verpackung</span>
              <span class="col-7 p-0" v-if="fields.packaging == 1">Objektverpackung</span>
            </div>
          </b-card>
        </div>
        <div class="col d-flex align-items-stretch">
          <b-card class=" flex-fill mb-3" header="Lieferadresse">
            {{fields.company.company}}<br>
            {{fields.address.street}} {{fields.address.housenr}}<br>
            {{fields.address.country_id}}-{{fields.address.zipcode}} {{fields.address.city}}
          </b-card>
        </div>
      </div>

      <div class="row" >
        <div class="col order-sm-1">
            <b-card header="Notizen">
                <b-card-body>
                  <span v-if="!notes.length">Keine Notizen vohanden</span>
                  <ol v-else>
                      <li v-for="note in notes" :key="note.id">
                        <div v-html="note.note"></div>
                      </li>
                  </ol>
                </b-card-body>
            </b-card>
        </div>
        <div class="col-9 order-sm-0 d-flex align-items-stretch">
          <b-card class=" flex-fill mb-3" header="Artikel">
            <div v-for="(article,index) in fields.articles" :key="article.id" class="article row mb-4">
              <div class="col-1">
                {{index+1}}.0
              </div>
              <div class="col-1">
                {{ article.articlenr }}
              </div>
              <div class="col-4">
                {{ article.name }}
                <div>
                  <span class="attribute badge badge-secondary mr-1" v-for="attribute in article.attributes" :key="attribute">
                    {{attribute.name}}: {{attribute.value}}
                  </span>
                </div>
              </div>
              <div class="col">
                <strong>Anzahl:</strong> {{ article.amount }}
              </div>
              <div class="col">
                <strong>Lieferdatum:</strong> {{ article.deliverydate }}
              </div>

              <div class="col-12">

              <div v-for="(subarticle,index2) in article.articles" :key="subarticle.id" class="subarticle row pb-1"  style="border-bottom: 1px solid #F1F1F1;">
                <div class="col-1">
                  {{index+1}}.{{index2+1}}
                </div>
                  <div class="col-1">
                    {{ subarticle.articlenr }}
                  </div>
                  <div class="col-4">
                    {{ subarticle.name }}
                    <div>
                      <span class="attribute badge badge-secondary mr-1" v-for="attribute in subarticle.attributes" :key="attribute">
                        {{attribute.name}}: {{attribute.value}}
                      </span>
                    </div>
                  </div>
                  <div class="col">
                    <strong>Anzahl:</strong> {{ subarticle.amount }}
                  </div>
                  <div class="col">
                    <strong>Lieferdatum:</strong> {{ subarticle.deliverydate }}
                  </div>
              </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Order',
  props: {
  },
  data(){
    return {
      selectOrderArticle: null,
      fields: {},
      notes: [],
      problems: [],
      errors: {},
      success: false,
      loaded: true,
      selected: null
    };
  },
  mounted() {
    this.selected = this.$route.params.id;
    this.load();
  },
  methods: {
    load() {
      this.errors = {};
      this.success = false;
      this.loaded = true;

      let self = this;
      let url = '/process/' + this.selected.toString() + '?detail=true';

      axios.get(url).then(res => {
        self.fields = res.data.order;
        self.problems = res.data.problems;
        self.notes = res.data.notes;
      }).catch(error => {
        alert('Ein Fehler ist aufgetreten');
        console.log(error);
      });
    },
    downloadPacklist() {
      let url = '/process/' + this.selected.toString() + '/downloadPacklist/';
      let filename = "A-" + this.fields.order.refnr + "_Packliste.pdf";
      this.download(url, filename);
    },
    download(url, filename){
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    supplier(e){
        if(!confirm("Soll dieser Auftrag wirklich als geliefert markiert werden?")) { return true}

        let self = this;
        let url = '/process/' + this.selected.toString() + "/setDeliver";

        axios.get(url).then(res => {
          self.fields = res.data.order;
        }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
