<template>
  <div id="app">
    <div class="container-fluid h-100">
        <div class="row flex-fill">
          <div class="col flex-column h-100">
            <div class="d-flex flex-column flex-grow-1 h-100" style="overflow: auto; overflow-x: hidden;">
              <router-view></router-view>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

}
</script>

<style>
</style>
