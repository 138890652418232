import VueRouter from 'vue-router'

import Order from "@/components/Order";
import ProcessList from "@/components/ProcessList";
import Login from "@/components/Login";
import ArticleList from "@/components/Article/ArticleList";


const routes = [
    {
        name: "login",
        path: '/login',
        component: Login,
        meta: {
            auth: false
        }
    },
    {
        name: "dashboard",
        path: '/',
        component: ProcessList,
        meta: {
            auth: false
        }
    },
    {
        name: 'order',
        path: '/orders/:id',
        component: Order,
        meta: {
            auth: false
        }
    },
    {
        name: 'articles',
        path: '/articles',
        component: ArticleList,
        meta: {
            auth: false
        }
    },
    {
        name: 'article',
        path: '/articles/:id',
        component: Order,
        meta: {
            auth: false
        }
    },
];

//const router = new VueRouter({routes});

const router = new VueRouter({
    history: true,
    //mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    //if (to.name !== 'login') next({ name: 'login' })
    // if the user is not authenticated, `next` is called twice
    next();
});

export default router;
