<template>
  <div class="processList">
    <b-modal size="xl" id="Article" title="Artikel generieren" ok-only ok-title="Generieren" @ok="save()" @show="show()" @shown="shown()">

    </b-modal>

    <div class="row mb-3">
      <h3 class="col-md-2 ">Artikel</h3>
      <div class="col-md-2 mb-1">
        <b-form-select v-model="status" :options="status_options" @change="load"></b-form-select>
      </div>
      <div class="col-md-3 mb-1">
        <b-input-group>
          <b-input placeholder="Suche" v-model="searchterm"></b-input>
          <b-input-group-append v-if="searchterm">
            <b-button  @click="searchterm = ''" title="Eingabe löschen" variant="danger"><i class="far fa-backspace"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-md-1 mb-1">
        <b-form-select v-model="per_page" :options="per_page_options" @change="load"></b-form-select>
      </div>
      <div class="col-md-1 text-center mb-1">
        <b-input v-model="datafeed.total" class="text-center" readonly></b-input>
      </div>
      <div class="col text-right">
        <b-pagination
            v-model="current_page"
            :total-rows="datafeed.total"
            :per-page="datafeed.per_page"
            align="right"
            class="my-0"
            @change="handlePageChange"

            no-local-sorting="false"
            :busy="busy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        ></b-pagination>

      </div>
    </div>

    <b-table striped hover size="sm" class="table-sm"
             :fields="fields"
             :items="datafeed.data"
             bordered
             sticky-header
             tbody-tr-class="d-flex"
             thead-tr-class="d-flex"
             no-local-sorting="false"
             :busy="busy"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             @sort="handlePageChange"
             @row-clicked="goto"
             stacked="xs">
      <template #head(priority)>
        <i class="far fa-flag" title="Priorität"></i>&nbsp;
      </template>
      <template #cell(priority)="row">
        <i class="far fa-flag" title="Priorität" :class="{ 'text-danger': row.value == 3, 'text-warning': row.value == 2, 'text-info': row.value == 1}"></i>&nbsp;
      </template>
      <template #cell(orderdate)="row">
        {{ row.value | moment("DD.MM.YYYY") }}
      </template>
      <template #cell(deliverydate)="row">
        {{ row.value | moment("DD.MM.YYYY")}}
      </template>
      <template #cell(refnr)="row">
        AB-{{ row.value}}
      </template>
      <template #cell(order.refnr)="row">
        A-{{ row.value}}
      </template>
    </b-table>
    <div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ArticleList',
  props: {

  },
  data() {
    return {
      busy: false,
      sortBy: 'deliverydate',
      sortDesc: false,
      loading: false,
      searchterm: '',
      awaitingSearch: false,
      current_page: 1,
      status: 'unclosed',
      status_options: {
        'all': 'alle Bestellungen',
        'unclosed': 'offene Bestellungen',
        'withoutDeliverynote': 'ohne Lieferschein',
        'closed': 'abgeschlossene Bestellungen'
      },
      per_page: 50,
      per_page_options: [5,10,15,20,25,30,50],
      datafeed:{
        total: 0,
        current_page: 1,
        per_page: 15,
        data:[]
      },
      color:{
        'delivered': 'text-success',
        'create': "text-warning",
        'waitingForArticle': "text-danger",
        'waitingForKey': "text-danger",
        'waitingForDisk': "text-danger",
        'waiting': 'text-danger',
        'ordered': 'text-info',
        'finished': 'text-success',
      },
      icon:{
        'create': 'far fa-plus-circle text-warning',
        'delivered': 'far fa-truck text-success',
        'waiting': 'far fa-hourglass-half text-danger',
        'waitingForArticle': "far fa-hourglass-half text-danger",
        'waitingForKey': "far fa-key text-danger",
        'waitingForDisk': "far fa-compact-disc text-danger",
        'ordered': 'far fa-sign-in text-info',
        'license': 'far fa-file-certificate',
        'disk': 'far fa-compact-disc',
        'embodiment': '',
      },
      payment_type:{
        'oxidinvoice': "Rechnung",
        'onpodebitnote': "Lastschrift",
        'fcpocreditcard': "Kreditkarte",
      },
      payment_type_icon:{
        'oxidinvoice': "far fa-file-invoice text-success",
        'fcpodebitnote': "far fa-file-contract text-warning",
        'fcpocreditcard': "far fa-credit-card text-info",
      },
      title: {
        'create': "Auftrag wurde erstellt",
        'delivered': 'Ware ausgeliefert',
        'waiting': 'Es liegt ein Problem vor!',
        'waitingForArticle': "Warenbestand fehlt",
        'waitingForKey': "Artikelposition wartet auf Key",
        'waitingForDisk': "MediaKit muss ausgeliefert werden",
        'ordered': 'Ware bei Muttergesellschaft bestellt',
      },
      fields: [
        { key: 'articlenr', label: 'Artikelnr.',thClass: "col-4 col-md-1", tdClass: 'col-12 col-md-1', sortable: true },
        { key: 'name', label: 'Artikelname',thClass: "col-4 col-md-4", tdClass: 'col-12 col-md-4', sortable: true },
      ],
      orders: [],
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    searchterm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.load();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
      //this.$cookie.set('order.searchterm', val);
    },
    sortBy(val){
      //this.$cookie.set('order.orderby', val);
      this.load();
    },
    sortDesc(val){
      //this.$cookie.set('order.orderdesc', val);
      this.load();
    },
  },
  methods:{
    findOverDue(){
      for(let i = 0; i < this.datafeed.data.length; i++){
        if(this.datafeed.data[i].overdue){
          this.datafeed.data[i]._rowVariant = 'danger';
        }
      }
    },
    load(){
      if(this.loading) return;

      this.loading = true;
      this.busy = true;

      let self = this;
      let url = '/article';
      let url_params = {
        'page': this.current_page,
        'per_page': this.per_page.toString(),
        'term': this.searchterm.toString(),
        'status': this.status.toString(),
        'sortBy': this.sortBy,
        'sortDesc': this.sortDesc,
      };


      axios.get(url,{
        params: url_params
      }).then(res => {
        self.datafeed = res.data;

        this.findOverDue();

        this.busy = false;
        this.loading = false;
      }).catch(error => {
        this.busy = false;
        this.loading = false;
        alert('Server meldet Fehler:' + error.response);
        console.log(error);
      });

      return true;
    },
    handlePageChange(value){
      this.current_page = value;
      return this.load();
    },
    goto(item, index, event){
      this.$bvModal.show('Article');
      //this.$router.push({ name: 'order', params: { id: item.id} });
    },
    show(bvModalEvt){

    },
    shown(bvModalEvt){

    },
    save(bvModalEvt) {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
