import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(IconsPlugin);

Vue.use(BootstrapVue)
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './routes'
import VueToast from 'vue-toast-notification';

import 'moment/locale/de';
import moment from "moment";
import VueMoment from 'vue-moment'
moment.locale('de');

import auth                  from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer      from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverHttpVueResource from '@websanova/vue-auth/src/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google    from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook  from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';

Vue.config.productionTip = false
axios.defaults.baseURL = "https://store.qles.de/api/";
Vue.router = router;

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

Vue.use(VueMoment, {
  moment
});


driverOAuth2Google.params.client_id = '547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com';
driverOAuth2Facebook.params.client_id = '196729390739201';

Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    // http: Vue.http, // Vue Resource
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios, // Axios
    //http: driverHttpVueResource, // Vue Resource
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook,
    }
  },
  options: {
    rolesKey: 'type',
    notFoundRedirect: {name: 'login'},
  }
});

Vue.use(VueToast,{
  position: 'bottom',
  duration: 60000,
})
new Vue({
  router,
  render: h => h(App),
  mounted(){
  }
}).$mount('#app')




