<template>
  <div id="login">

    <div class="container">
        <div class="col-md-6 offset-md-3">
            <div class="card mt-5">
                <div class="card-header bg-primary text-white">
                    <b>Login</b>
                </div>
                <div class="card-body">
                    <div class="form-group mb-3">
                        <label for="user">Benutzer</label>
                        <input type="email" id="user" class="form-control" v-model="formData.email">
                    </div>
                  <div class="form-group mb-3">
                    <label for="password">Passwort</label>
                    <input type="password" id="password" class="form-control" v-model="formData.password">
                  </div>
                  <div class="d-grid gap-2">
                    <button class="btn btn-primary btn-lg btn-block" @click="login">Login</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data(){
    return {
        formData: {
          email: '',
          password: '',
        },
        has_error: false
    };
  },
  methods:{
    login(){
      let redirect = this.$auth.redirect();
      let app = this;

      this.$auth.login({
        params: {
          email: this.formData.email,
          password: this.formData.password
        },
        rememberMe: true,
        fetchUser: true
      }).then(response => {
        app.has_error = false;
        this.$router.push({name: 'dashboard'});
      }, (res) => {
        this.$toast.error('E-Mail Adresse oder Passwort sind nicht korrekt. Bitte versuche es erneut!');
      });
    }
  }
}

</script>

<style scoped>

</style>